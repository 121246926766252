<template>
  <section class="one-project fade">
    <Loader v-if="loading" />
    <div
      class="inline-grid-container container-padding page-block"
    >
          <LandingTitleBlock
              is-one-project
              :title="project.title"
              :pic_url="project.pic_url"
              :data="project.data"
              line-height="50px"
            />
            <div
              class="project-wrapper"
              v-html="project.text"
            >
            </div>
    </div>

    <Projects is-one-project-page />
    <Feedback />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import img from '@/assets/img/about_fond_img.svg'
import Loader from '../../components/UI/Loader.vue';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import ProjectSlider from './components/ProjectSlider.vue';
import CustomLink from '../../components/UI/CustomLink.vue';
import Feedback from '@/components/Feedback.vue';
import Projects from '@/views/mainPage/components/Projects.vue';
import { changeUrl } from '@/helpers/commonHelpers';

export default {
  name: 'Project',

  data () {
    return {
      loading: true,
      project: null,
      img,
    }
  },

  watch: {
    $route: async function (to, from) {
      this.loading = true
      this.project = await this.getProject(this.$route.params.id)
      this.loading = false
    }
  },

  methods: {
    ...mapActions('projects', ['getProject', 'getProjects']),
    changeUrl,
  },

  async mounted () {
    this.project = await this.getProject(this.$route.params.id)
    this.project.text = changeUrl(this.project.text)
    await this.getProjects(5)
    this.loading = false
  },

  components: { Loader, LandingTitleBlock, ProjectSlider, Feedback, CustomLink, Projects },
};
</script>

<style scoped lang="scss">
.swiper-wrapper {
  min-height: 305px !important;
}

.project-slider {
  margin: 150px 90px 0 90px;
  padding-bottom: 120px;
}

.project-slider__title {
  display: flex;
  align-items: flex-end;
  margin: 0 0 30px 120px;
  h3 {
    margin-right: 10px;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
  }
}

@media (max-width: 1100px) {
  .page-block {
    margin-bottom: 56px;
  }
}
</style>
