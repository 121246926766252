<template>
  <swiper
    ref="projectsSwiper" :options="swiperOptions" class="swiper"
    :delete-instance-on-destroy="true"
    :cleanup-styles-on-destroy="true"
  >
    <swiper-slide v-for="el in filteredProjects" :key="el.id" class="slider__slide">
      <ProjectItem :project="el" margin @openProject="$emit('reLinkProject', el.id)"/>
    </swiper-slide>
    <div class="swiper-scrollbar" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapState } from 'vuex';
import ProjectItem from '../../../components/ProjectItem.vue';

export default {
  name: 'ProjectSlider',

  computed: {
    ...mapState('projects', ['projects']),
    ...mapState(['swiperOptions']),

    swiper() {
      return this.$refs.projectsSwiper.$swiper
    },
    filteredProjects() {
      return this.projects.filter(el => !el.big_block)
    },
  },

  components: { ProjectItem, Swiper, SwiperSlide },
}
</script>

<style scoped>
.slider__slide {
  width: 460px;
}
</style>
